<template>
  <section class="gardenManagementPage">
    <div class="info f-22" style="margin-bottom: 20px">
      <p class="title border-bottom f-w">商城信息</p>
      <el-row>
        <el-col :span="6" v-for="(item, index) in infoList" :key="index"
          ><div class="t-c">
            <p class="m-top15 f-20">{{ item.num }}</p>
            <p class="m-top15 f-24">{{ item.name }}</p>
          </div></el-col
        >
      </el-row>
    </div>

    <div class="dis-flex flex-x-between flex-y-start" style="height: 100%">
      <div class="info f-22" style="width: 49%; padding: 10px">
        <div
          class="title dis-flex flex-x-between flex-y-center"
          style="background-color: rgba(88, 227, 232, 0.12); padding: 10px"
        >
          <p class="f-w">订单概述</p>

          <div class="flex-1 dis-flex flex-x-end flex-y-center">
            <p
              v-for="(item, index) in timeList"
              :key="index"
              class="info-bottom t-c"
              :class="timeSelect == item.id ? 'bg-58e3e8 col-f' : 'bg-f'"
              @click="orderClick(item.id)"
            >
              {{ item.name }}
            </p>
          </div>
        </div>

        <div
          class="dis-flex flex-x-between flex-y-end m-bom30 padding-btm20 box-sizing"
          style="background-color: rgba(88, 227, 232, 0.12); padding: 10px"
        >
          <div class="t-c width33" v-for="(item, index) in orderList" :key="index">
            <p class="m-top15 col-facf5b f-28">{{ item.num }}</p>
            <p class="m-top15">{{ item.name }}</p>
          </div>
        </div>
        <div
          id="myChart"
          style="width: 100%; height: 300px; background-color: #fff"
          class="m-bom20"
        ></div>
      </div>
      <div class="info f-22" style="width: 49%; height: 100%">
        <div class="title dis-flex flex-x-between flex-y-center">
          <p class="f-w">商品销量排行</p>

          <div class="flex-1 dis-flex flex-x-end flex-y-center">
            <p
              v-for="(item, index) in timeList"
              :key="index"
              class="info-bottom t-c"
              :class="saleSelect == item.id ? 'bg-58e3e8 col-f' : 'bg-f'"
              @click="saleClick(item.id)"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
        <el-table
          :data="tableData"
          stripe
          style="width: 100%"
          border
          :header-cell-style="tabHeader"
          :cell-style="{ textAlign: 'center' }"
        >
          <el-table-column prop="no" label="排名" min-width="30" />

          <el-table-column prop="goodsName" label="商品名称" min-width="30" />

          <el-table-column prop="num" width="300" label="成交数量" />
        </el-table>
      </div>
    </div>
  </section>
</template>

<script>
import {
  querycshopmallData,
  querycshopmallOrderData,
  querycshopmallOrderGoods,
  querycshopmallOrderLine,
} from "@api/bx/api_contentManagement";
const echarts = require("echarts");
export default {
  data() {
    return {
      tableData: [],
      infoList: [
        { name: "用户数", num: 0, id: 0 },
        { name: "商品数", num: 0, id: 1 },
        { name: "订单数", num: 0, id: 2 },
        { name: "待发货订单", num: 0, id: 3 },
      ],
      orderList: [
        { name: "成交量（件）", num: 0, id: 0 },
        { name: "成交额（元）", num: 0, id: 1 },
        { name: "订单平均消费（元）", num: 0, id: 2 },
      ],
      timeList: [
        { name: "今日", num: 0, id: "0" },
        { name: "昨日", num: 0, id: "1" },
        { name: "最近7天", num: 0, id: "2" },
        { name: "最近30天", num: 0, id: "3" },
      ],
      timeSelect: "0",
      saleSelect: "3",
      day: [],
      dealnum:[],
      dealvolume:[]
    };
  },
  created() {
    // this.initChart();
    this.querycshopmallOrderLine();
    this.querycshopmallData();
    this.querycshopmallOrderData();
    this.querycshopmallOrderGoods();
  },
  mounted() {
    const myChart = echarts.init(document.getElementById("myChart"));
    window.addEventListener("resize", function () {
      // 指的要改变大小的图表
      myChart.resize();
    });
    this.initChart();
  },
  methods: {
    tabHeader() {
      return "backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;";
    },

    orderClick(id) {
      this.timeSelect = id;
      this.querycshopmallOrderData();
    },
    saleClick(id) {
      this.saleSelect = id;
      this.querycshopmallOrderGoods();
    },
    // 商城信息
    querycshopmallData() {
      querycshopmallData({}).then((res) => {
        this.infoList[0].num = res.usernum;
        this.infoList[1].num = res.goodsnum;
        this.infoList[2].num = res.ordernum;
        this.infoList[3].num = res.waitordernum;
      });
    },
    //订单概述
    querycshopmallOrderData() {
      querycshopmallOrderData({ type: this.timeSelect }).then((res) => {
        this.orderList[0].num = res.dealnum;
        this.orderList[1].num = res.dealvolume;
        this.orderList[2].num = res.orderagvdeal;
      });
    },

    //商品销量排行
    querycshopmallOrderGoods() {
      querycshopmallOrderGoods({ type: this.saleSelect }).then((res) => {
        this.tableData = res;
      });
    },

    //趋势图
    querycshopmallOrderLine() {
      querycshopmallOrderLine({}).then((res) => {
        res.map((item, index) => {
          this.day.push(item.day);
          this.dealnum.push(item.dealnum);
          this.dealvolume.push(item.dealvolume);
        });
        this.initChart()
      });
    },

    initChart() {
      let myChart = echarts.init(document.getElementById("myChart"));
      var option = {
        title: {
          text: "近7日交易走势",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["成交量", "成交额"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data:this.day,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "成交量",
            type: "line",
            stack: "总量",
            data: this.dealnum,
          },
          {
            name: "成交额",
            type: "line",
            stack: "总量",
            data: this.dealvolume,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>

<style scoped lang="scss">
/* .tree /deep/ .el-tree-node__content{
		padding-left: 40px
	} */
.turnBule {
  color: #333;
  background-color: #58e3e8;
  border-color: rgba(56, 185, 190, 0.12);
  margin-left: 15px;
}

.turnBule:hover,
.turnBule:focus {
  background: rgba(56, 185, 190, 0.5);
  border-color: rgba(56, 185, 190, 0.5);
  color: #fff;
}

.el-button--text.reviewed {
  color: #38b9be;
}

.reviewed {
  color: #38b9be;
}

.el-tree-node__content {
  z-index: 999;
  padding-left: 40px;
}

.directory-in {
  width: 32%;
  line-height: 60px;
  background: #4095e5;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
// .el-col {
//   border-radius: 4px;
// }
.info {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  // margin-bottom: 20px;
  box-sizing: border-box;
  .title {
    // border-bottom: 1px solid #eee;
    padding-bottom: 20px;
    box-sizing: border-box;
    .info-bottom {
      width: 100px;
      line-height: 40px;
      border: 1px solid #eee;
    }
  }
}
</style>
